import React from "react";

import useStyles from "@hooks/useStyles";
import withStyles from "@framework/src/libs/withStyles";
import { observer } from "mobx-react";
import { compose } from "recompose";
import useConstants from "@hooks/useConstants";
import withConstants from "@framework/src/libs/withConstants";
import { constants, styles } from "./.config";

import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import { adjective as proAdjective } from "@language/provider";

import FindInPageIcon from "@material-ui/icons/FindInPage";
import BeenhereIcon from "@material-ui/icons/Beenhere";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";

const { BRAND_NAME } = process.env;

const HowItWorks = compose(
	withStyles(styles),
	withConstants(constants),
	observer
)(() => {
	const classes = useStyles("HowItWorks");
	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	};

	const { title, fact_1, bullet_1, fact_2, bullet_2, fact_3, bullet_3 } =
		useConstants("HowItWorksViewModel");

	return (
		<Container maxWidth="md">
			<Box p={{ xs: 4 }}>
				<Box
					component="div"
					pt={8}
					mb={12}
					textAlign="center"
					fontSize={26}
					fontWeight={600}
				>
					{title}
				</Box>

				<Box component="div" mt={6} textAlign="center">
					<Grid container spacing={8}>
						<Grid item xs={12} sm={4}>
							<FindInPageIcon className={classes.stepIcon} />

							<div className={classes.stepHead}>
								{fact_1 ? fact_1 : "Search"}
							</div>
							<p className={classes.stepP}>
								{bullet_1
									? bullet_1
									: `Search, get cost estimates, contact ${proAdjective.plural}, and book—all for free.`}
							</p>
						</Grid>

						<Grid item xs={12} sm={4}>
							<SupervisedUserCircleIcon className={classes.stepIcon} />

							<div className={classes.stepHead}>
								{fact_2 ? fact_2 : `Compare`}
							</div>
							<p className={classes.stepP}>
								{bullet_2
									? bullet_2
									: "View profiles, read reviews, check qualifications, and see prices before hiring."}
							</p>
						</Grid>

						<Grid item xs={12} sm={4}>
							<BeenhereIcon className={classes.stepIcon} />

							<div className={classes.stepHead}>{fact_3 ? fact_3 : "Hire"}</div>
							<p className={classes.stepP}>
								{bullet_3
									? bullet_3
									: `Ask questions, confirm their availability, and hire the right ${proAdjective.singular} when you're ready.`}
							</p>
						</Grid>
					</Grid>
				</Box>

				<Box component="div" mt={8} textAlign="center" pb={8}>
					<Button
						onClick={scrollToTop}
						variant="outlined"
						disableElevation
						size="large"
						color="primary"
					>
						Start now
					</Button>
				</Box>
			</Box>
		</Container>
	);
});

export default HowItWorks;
