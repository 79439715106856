import gql from "graphql-tag";

export const serviceQueryFragment = gql`
	fragment serviceQueryFragment on Service {
		_id
		midtail
	}
`;

export const getProviderProfileImagesQueryFragment = gql`
	fragment getProviderProfileImagesQueryFragment on ProviderImagePayload {
		providerId
		image {
			value
		}
	}
`;

export const findOpenLeadQueryFragment = gql`
	fragment findOpenLeadQueryFragment on Lead {
		key
	}
`;
