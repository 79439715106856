import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { compose } from "recompose";

// Hooks
import { useLocation } from "react-router-dom";
import useStyles from "@hooks/useStyles";
import useStores from "@libs/framework/src/hooks/useStores";

import Avatar from "@material-ui/core/Avatar";

// Components
import LinkTo from "@custom/LinkTo";
import ProListItemContent from "./ProListItemContent";

import { replaceUrlParam } from "@utils/Browser";
import FinalPrice from "@components/Global/Custom/FinalPrice";
import Button from "@material-ui/core/Button";
import ButtonBase from "@material-ui/core/ButtonBase";
import SendOutlinedIcon from "@material-ui/icons/SendOutlined";
import withStores from "@libs/framework/src/libs/withStores";
import withStyles from "@framework/src/libs/withStyles";

// Language
import { callToAction as clientCallToAction } from "@language/client";

const { SITE_NAME } = process.env;
import { styles, stores } from "./.config";
import LazyLoadComponent from "@custom/LazyLoad";

const ProListItem = compose(
	withStores(stores),
	withStyles(styles),
	observer
)(({ provider, variant, leadKey, serveCity, onMouseEnter, onMouseLeave }) => {
	const classes = useStyles("ProList");
	const location = useLocation();

	const {
		proListItemStore: store,
		proListStore,
		sendProjectStore,
	} = useStores();

	let search = location.search;
	if (proListStore.urlServiceKey) {
		search = replaceUrlParam(
			search,
			proListStore.isMidtail ? "midtail" : "service",
			proListStore.urlServiceKey
		);
	}

	const sendRequestOnClickHandler = (e) => {
		e.preventDefault();
		sendProjectStore.init({ leadKey, providerId: provider._id });
	};

	const contactOnClickHandler = (e) => {
		e.preventDefault();
		store.contactPro(provider._id);
		store.showLeadFunnel();
	};

	return (
		<LinkTo
			pathname={store.profileLink(provider)}
			search={search}
			variant={variant}
		>
			<article
				className={classes.proBox}
				onMouseEnter={(e) => onMouseEnter(e, provider)}
				onMouseLeave={(e) => onMouseLeave(e, provider)}
			>
				<div className={classes.proBoxFlex}>
					<LazyLoadComponent>
						<Avatar
							id={provider._id}
							variant={"rounded"}
							className={classes.proImg}
							alt={provider.company_name.ucFirst()}
							title={provider.company_name.ucFirst()}
							src={provider.image?.value}
							imgProps={{
								loading: "lazy",
								decoding: "async",
								fetchpriority: "auto",
							}}
						/>
					</LazyLoadComponent>
					<ProListItemContent
						provider={provider}
						leadKey={leadKey}
						serveCity={serveCity}
					/>
					<div className={classes.proPriceWrapper}>
						{!["vety"].includes(SITE_NAME) && (
							<>
								{store.leadFunnelExists && !leadKey ? (
									<ButtonBase variant={"text"} onClick={contactOnClickHandler}>
										<FinalPrice
											total={provider.estimated_price?.total}
											unit={provider.estimated_price?.unit}
											description={provider.estimated_price?.description}
										/>
									</ButtonBase>
								) : (
									<FinalPrice
										total={provider.estimated_price?.total}
										unit={provider.estimated_price?.unit}
										description={provider.estimated_price?.description}
									/>
								)}
							</>
						)}

						<div className={classes.proButtons}>
							{leadKey ? (
								<Button
									onClick={sendRequestOnClickHandler}
									disableElevation
									variant="contained"
									color="primary"
									startIcon={<SendOutlinedIcon />}
								>
									Send request
								</Button>
							) : (
								<>
									<div className={classes.proButtonsSpacing}>
										<Button
											onClick={contactOnClickHandler}
											fullWidth
											disableElevation
											variant="contained"
											color="primary"
										>
											{clientCallToAction.lead.charAt(0).toUpperCase() +
												clientCallToAction.lead.slice(1)}
										</Button>
									</div>
									<Button
										fullWidth
										disableElevation
										variant="outlined"
										color="primary"
									>
										View profile
									</Button>
								</>
							)}
						</div>
					</div>
				</div>
			</article>
		</LinkTo>
	);
});

ProListItem.propTypes = {
	provider: PropTypes.object.isRequired,
};

export default ProListItem;
