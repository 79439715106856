export default (theme) => ({
	stepHead: {
		marginTop: 16,
		marginBottom: 12,
		textAlign: "center",
		fontWeight: 600,
		fontSize: 18,
	},
	stepP: {
		fontSize: 14,
		lineHeight: 1.6,
	},
	stepIcon: {
		fontSize: 60,
		color: theme.palette.primary.main,
	},
});
