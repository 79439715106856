import * as queries from "./query";

import Service from "@services/Service";
import Provider from "@services/Provider";
import Lead from "@services/Lead";

export default {
	...new Service({ queries }),
	...new Provider({ queries }),
	...new Lead({ queries }),
};
