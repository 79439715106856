import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const LinkTo = ({ pathname, search, variant, children }) => {
	const linkTypes = {
		ssr: (
			<a href={`${pathname}${search}`} target="_blank">
				{children}
			</a>
		),
		spa: <Link to={{ pathname, search }}>{children}</Link>,
		window: (
			<a href={`${pathname}${search}`} target="_blank">
				{children}
			</a>
		),
	};

	return linkTypes[variant];
};

LinkTo.defaultProps = {
	variant: "ssr",
};

LinkTo.propTypes = {
	children: PropTypes.node.isRequired,
	pathname: PropTypes.string,
	search: PropTypes.string,
	variant: PropTypes.oneOf(["ssr", "spa", "window"]),
};

export default LinkTo;
