import { observable, action, runInAction } from "mobx";
import rootService from "../@data/service";
import { getParameterByName } from "@utils/Browser";
import required from "@libs/required";
import { string } from "@src/libs/yup";

const { getProviderProfileImages, findOpenLead } = rootService;

class ProList {
	@observable service;
	@observable lead;
	@observable providers = [];
	@observable hiddenProviders = [];

	@observable seeMoreCounter = 0;

	viewableLimit = 10;
	staticContext = {};

	constructor(rootStore, staticContext) {
		this.staticContext = staticContext;
		this.rootStore = rootStore;

		const { service, midtail, providers, app } = staticContext;
		this.service = midtail ? { ...midtail, midtail: true } : service;
		this.providers = providers?.slice(0, this.viewableLimit) || [];
		this.hiddenProviders =
			providers?.slice(this.viewableLimit, providers.length) || [];
	}

	get serviceKey() {
		return getParameterByName("service") || getParameterByName("midtail");
	}

	get accountStore() {
		const { accountStore } = this.rootStore.stores;
		return accountStore;
	}

	get searchPanelStore() {
		const { searchPanelStore } = this.rootStore.stores;
		return searchPanelStore;
	}

	get leadFunnelExists() {
		const { leadFunnelModalStore } = this.rootStore.stores;
		return !!leadFunnelModalStore;
	}

	get showSeeMore() {
		return this.hiddenProviders.length > 0;
	}

	get isMidtail() {
		return this.service?.midtail;
	}

	get urlServiceKey() {
		if (this.serviceKey) return this.serviceKey;
		if (this.service) return this.service.key;
	}

	get zipcode() {
		const val =
			getParameterByName("zipcode") ||
			this.staticContext.app?.location?.zipcode;

		if (this.searchPanelStore?.zipcode) {
			const zipCodeValidation = string().zipcode();
			try {
				zipCodeValidation.validateSync(this.searchPanelStore.zipcode);
				return this.searchPanelStore.zipcode;
			} catch (err) {
				return val;
			}
		}
		return val;
	}

	@action
	seeMore() {
		if (this.hiddenProviders.length === 0) return;
		this.seeMoreCounter += 1;
		this.providers = [
			...this.providers,
			...this.hiddenProviders.slice(
				0,
				this.seeMoreCounter * this.viewableLimit
			),
		];
		this.hiddenProviders = this.hiddenProviders.slice(
			this.seeMoreCounter * this.viewableLimit,
			this.hiddenProviders.length
		);
	}

	async init(providers = [], forceShowAllPros) {
		try {
			const providerIds = providers.filter((p) => !p.image).map((p) => p._id);
			const [imageResponses] = await Promise.all([
				providerIds.length > 0
					? getProviderProfileImages(
							{ providerIds, width: 400, height: 400 },
							{ batch: true }
					  )
					: Promise.resolve(),
			]);

			runInAction(() => {
				if (imageResponses) {
					this.providers = providers.map((provider) => {
						const imageResponse = imageResponses.find(
							(imageResponse) => imageResponse.providerId === provider._id
						);

						return {
							...provider,
							image: imageResponse?.image,
						};
					});
				} else if (!forceShowAllPros) {
					this.providers = providers.slice(0, this.viewableLimit);
				} else {
					this.providers = providers;
				}
				if (!forceShowAllPros)
					this.hiddenProviders = providers.slice(
						this.viewableLimit,
						providers.length
					);
			});
		} catch (e) {
			throw e;
		}
	}

	async setOpenLead(serviceKey) {
		if (!serviceKey) {
			if (this.serviceKey) {
				serviceKey = this.serviceKey;
			} else if (this.service?.midtail === false && this.service?.key) {
				serviceKey = this.service.key;
			}
		}

		if (!serviceKey) return this.resetLead();
		if (!this.zipcode) return this.resetLead();
		if (
			this.accountStore.type !== "CLIENT" &&
			this.accountStore.type !== "USER"
		)
			return this.resetLead();
		try {
			const lead = await findOpenLead(
				{ serviceKey, zipcode: this.zipcode },
				{ batch: true }
			);

			runInAction(() => {
				this.lead = lead;
			});
		} catch (e) {
			throw e;
		}
	}

	@action
	resetLead() {
		this.lead = null;
	}

	@action
	resetService() {
		const { service, midtail } = this.staticContext;
		this.service = midtail ? { ...midtail, midtail: true } : service;
	}
}

export default ProList;
