import { observable, action, computed } from "mobx";

// Language
import { callToAction as clientCallToAction } from "@language/client";

class ProListItemStore {
	reviewAverageScores = {
		high: 4.9,
		medium: 4.7,
		low: 4.5,
	};

	@observable selectedProvider = null;

	constructor(rootStore, staticContext) {
		this.rootStore = rootStore;
		this.handleMouseOverFn = () => {};
	}

	get leadFunnelExists() {
		const { proListStore } = this.rootStore.stores;
		return proListStore.leadFunnelExists;
	}

	@action
	contactPro(providerId) {
		const { proListStore } = this.rootStore.stores;
		this.selectedProvider = proListStore.providers.find(
			(pro) => pro._id === providerId
		);
	}

	profileLink(provider) {
		if (!provider.profile?.url) return "";
		return "/" + provider.profile?.url;
	}

	reviewStatus(provider) {
		const reviewsAverage = provider.metrics.reviews_avg.toFixed(1);

		if (reviewsAverage >= this.reviewAverageScores.high) return "Exceptional";

		if (
			this.reviewAverageScores.medium <= reviewsAverage &&
			reviewsAverage < this.reviewAverageScores.high
		)
			return "Excellent";

		if (
			this.reviewAverageScores.low <= reviewsAverage &&
			reviewsAverage < this.reviewAverageScores.medium
		)
			return "Awesome";

		return "";
	}

	yearsInService(foundingYear) {
		const today = new Date();
		const founded = foundingYear ? parseInt(foundingYear) : null;

		return foundingYear ? today.getFullYear() - founded : 0;
	}

	bio(provider) {
		const str = provider.bio
			? provider.bio.replace(/<(?:.|\n)*?>/gm, "")
			: provider.intro;

		if (!str) return "";

		return {
			value: str.substring(0, 650),
			originalLength: str.length,
		};
	}

	showLeadFunnel() {
		const { leadFunnelModalStore } = this.rootStore.stores;
		leadFunnelModalStore.leadActionType =
			clientCallToAction.lead === "request a quote"
				? "requested_quote"
				: "requested_availability";
		leadFunnelModalStore.show = true;
	}

	handleMouseOver(e, ...args) {
		this.handleMouseOverFn(e, ...args);
	}
}

export default ProListItemStore;
