import React, { memo } from "react";
import PropTypes from "prop-types";

// Hooks
import useStyles from "@libs/framework/src/hooks/useStyles";

// MUI
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";

// MUI Icons
import VideocamOutlinedIcon from "@material-ui/icons/VideocamOutlined";
import StarsIcon from "@material-ui/icons/Stars";
import EmojiEventsOutlinedIcon from "@material-ui/icons/EmojiEventsOutlined";

// Language
import { adjective as proAdjective } from "@language/provider";
import withStyles from "@libs/framework/src/libs/withStyles";

// Config
const { BRAND_NAME } = process.env;
import { styles } from "./.config";

export const ProListItemBadges = withStyles(styles)(
	memo(({ badge = {} }) => {
		const classes = useStyles("ProList");

		return (
			<React.Fragment>
				{badge.top_pro && (
					<div className={classes.profileChip}>
						<Tooltip
							enterDelay={400}
							title={`Top Pros are the highest-rated ${proAdjective.plural} on ${BRAND_NAME}.`}
							arrow
							TransitionComponent={Zoom}
							placement={"top"}
						>
							<Chip
								size="small"
								icon={<StarsIcon className={classes.iconGreen} />}
								label="Top Pro"
								className={classes.chipGreen}
							/>
						</Tooltip>
					</div>
				)}

				{badge.high_demand && (
					<div className={classes.profileChip}>
						<Tooltip
							enterDelay={400}
							title={`Lots of people are contacting this ${proAdjective.singular}, so message them soon.`}
							arrow
							TransitionComponent={Zoom}
							placement={"top"}
						>
							<Chip
								size="small"
								icon={<EmojiEventsOutlinedIcon className={classes.iconBlue} />}
								label="In high demand"
								className={classes.chipBlue}
							/>
						</Tooltip>
					</div>
				)}

				{badge.online && (
					<div className={classes.profileChip}>
						<Tooltip
							enterDelay={400}
							title={`Connect with this ${proAdjective.singular} via an online session.`}
							arrow
							TransitionComponent={Zoom}
							placement={"top"}
						>
							<Chip
								size="small"
								icon={<VideocamOutlinedIcon className={classes.iconPurple} />}
								label="Offers online services"
								className={classes.chipPurple}
							/>
						</Tooltip>
					</div>
				)}
			</React.Fragment>
		);
	})
);

ProListItemBadges.propTypes = {
	badge: PropTypes.object.isRequired,
};

export default ProListItemBadges;
