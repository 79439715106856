import React, { memo } from "react";
import PropTypes from "prop-types";

// Hooks
import useStyles from "@libs/framework/src/hooks/useStyles";
import useStores from "@libs/framework/src/hooks/useStores";

// MUI
import Rating from "@material-ui/lab/Rating";
import Button from "@material-ui/core/Button";
import ButtonBase from "@material-ui/core/ButtonBase";

// MUI Icons
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import ChatBubbleOutlineOutlinedIcon from "@material-ui/icons/ChatBubbleOutlineOutlined";
import ScheduleIcon from "@material-ui/icons/Schedule";

// Components
import ProListItemBadges from "./ProListItemBadges";
import OnlinePulse from "@components/Global/Custom/OnlinePulse";
import FinalPrice from "@components/Global/Custom/FinalPrice";
import SendOutlinedIcon from "@material-ui/icons/SendOutlined";

// Language
import { callToAction as clientCallToAction } from "@language/client";

// Config
const { BRAND_NAME, SITE_NAME } = process.env;

const showBadges = true;

export const ProListItemContent = memo(({ provider, leadKey, serveCity }) => {
	const classes = useStyles("ProList");
	const {
		proListItemStore: store,
		proListStore,
		sendProjectStore,
	} = useStores();

	const yearsInService = store.yearsInService(provider.company_founded);
	const hasActiveProfile = provider.profile.active;
	const bio = store.bio(provider);

	const providerServesLocation =
		provider.business_location || provider.location;

	const contactOnClickHandler = (e) => {
		e.preventDefault();
		store.contactPro(provider._id);
		store.showLeadFunnel();
	};

	const sendRequestOnClickHandler = (e) => {
		e.preventDefault();
		sendProjectStore.init({ leadKey, providerId: provider._id });
	};

	return (
		<div className={classes.proContent}>
			{/* Name */}
			<div className={classes.proNameBox}>
				<h3 className={classes.proName}>
					{`${provider.company_name.ucFirst()}`}
				</h3>
			</div>
			{/* Reviews */}
			{provider.metrics.reviews_count ? (
				<div className={classes.proReviews}>
					<span className={classes.proReviewsAvg}>
						{provider.metrics.reviews_avg.toFixed(1)}
					</span>

					<Rating
						value={provider.metrics.reviews_avg}
						precision={0.5}
						readOnly
						size="small"
					/>

					{provider.metrics.reviews_count > 4 &&
						provider.metrics.reviews_avg >= 4.5 && (
							<span className={classes.proReviewsStatus}>
								{store.reviewStatus(provider)}
							</span>
						)}

					<span className={classes.proReviewsCount}>
						{`(${provider.metrics.reviews_count})`}
					</span>
				</div>
			) : (
				<div className={classes.proFacts}>
					<div className={classes.proFactsBox}>
						<Rating max={1} value={1} readOnly size="small" />

						<span className={classes.proFactsText}>New on {BRAND_NAME}</span>
					</div>
				</div>
			)}
			{/* <div className={`${classes.proFacts} ${classes.proFactsHiddenMobile}`}>
				<span className={classes.proFactsBox}>
					<span>
						{`${provider.location.city.ucFirst()}, ${provider.location.state.toUpperCase()}`}
						{yearsInService !== 0 && (
							<span>
								<span className={classes.proFactsB}>•</span>
								{`${yearsInService} ${
									yearsInService > 1 ? "years" : "year"
								} in business`}
							</span>
						)}
					</span>
				</span>
			</div> */}
			{/* Badges */}
			<div className={classes.proBadges}>
				<ProListItemBadges badge={provider.award} />
			</div>
			{/* Number of hires */}
			{provider.metrics.hires !== 0 && (
				<div className={`${classes.proFacts6}`}>
					<span className={classes.proFactsBox}>
						<TrendingUpIcon fontSize="small" className={classes.proFactsIcon} />
						<span className={classes.proFactsText}>
							<span className={classes.proFactsTextB}>
								{`${provider.metrics.hires} ${
									provider.metrics.hires === 1 ? "hire" : "hires"
								}`}
							</span>
							{` on ${BRAND_NAME}`}
						</span>
					</span>
				</div>
			)}
			{/* Years in service */}
			{/* <Box className={`${classes.proFacts} ${classes.proFactsHiddenMobile}`}>
				<span className={classes.proFactsBox}>
					<span>
						{`${provider.location.city.ucFirst()}, ${provider.location.state.toUpperCase()}`}
						{yearsInService !== 0 && (
							<span>
								<span className={classes.proFactsB}>•</span>
								{`${yearsInService} ${
									yearsInService > 1 ? "years" : "year"
								} in business`}
							</span>
						)}
					</span>
				</span>
			</Box> */}
			{yearsInService > 1 && (
				<div className={`${classes.proFacts6}`}>
					<span className={classes.proFactsBox}>
						<ScheduleIcon fontSize="small" className={classes.proFactsIcon} />
						<span className={classes.proFactsText}>
							<span>
								{`${yearsInService}${
									yearsInService > 1 ? "+ years" : "+ year"
								} in business`}
							</span>
						</span>
					</span>
				</div>
			)}
			{serveCity && (
				<div className={`${classes.proFacts6}`}>
					<span className={classes.proFactsBox}>
						<LocationOnOutlinedIcon
							fontSize="small"
							className={classes.proFactsIcon}
						/>
						<span className={classes.proFactsText}>
							{`Serves ${serveCity?.city?.ucFirst()}, ${serveCity?.state?.toUpperCase()}`}
						</span>
						{/* {yearsInService > 1 && (
							<span>
								<span className={classes.proFactsB}>•</span>
								{`${yearsInService}${
									yearsInService > 1 ? "+ years" : "+ year"
								} in business`}
							</span>
						)} */}
					</span>
				</div>
			)}
			{/* Responce time (Hidden for now) */}
			{/* 			<div className={`${classes.proFacts6}`}>
				<span className={classes.proFactsBox}>
					<SmsOutlinedIcon fontSize="small" className={classes.proFactsIcon} />
					<span className={classes.proFactsText}>
						Responds within{" "}
						<span className={classes.proFactsTextB}>3 hours</span>
					</span>
				</span>
			</div> */}
			{/* Online status */}
			{provider.online && (
				<div className={`${classes.proFacts}`}>
					<span className={classes.proFactsBox}>
						<OnlinePulse />

						<span className={classes.proFactsText}>{`Online now`}</span>
					</span>
				</div>
			)}
			{/* Bio/Intro */}

			{provider.reviews?.length > 0 && provider.reviews[0]?.message?.body ? (
				<div className={classes.proAboutMessage}>
					<div className={classes.proAbout}>
						<span className={classes.proFactsTextB}>
							{`${provider.reviews[0]?.author.ucFirst()} says, "`}
						</span>
						<span>{`${provider.reviews[0]?.message?.body}" `}</span>
						<span className={classes.proAboutLink}>Read more</span>
					</div>
				</div>
			) : (
				<div className={classes.proAboutBox}>
					<div className={classes.proAbout}>
						{bio.value}
						{bio.originalLength > 450 && hasActiveProfile && <span>...</span>}
					</div>
				</div>
			)}

			{/* Price (Mobile only) */}
			<div className={classes.proPriceWrapperMobile}>
				{!["vety"].includes(SITE_NAME) && (
					<>
						{store.leadFunnelExists && !leadKey ? (
							<ButtonBase variant={"text"} onClick={contactOnClickHandler}>
								<FinalPrice
									total={provider.estimated_price?.total}
									unit={provider.estimated_price?.unit}
									description={provider.estimated_price?.description}
									isMobile
								/>
							</ButtonBase>
						) : (
							<FinalPrice
								total={provider.estimated_price?.total}
								unit={provider.estimated_price?.unit}
								description={provider.estimated_price?.description}
								isMobile
							/>
						)}
					</>
				)}
			</div>
			{/* Mobile action buttons */}
			{/*<div className={classes.proButtonsMobile}>*/}
			{/*    <Box display="flex" width="100%">*/}
			{/*        <Button fullWidth variant="outlined" size="small" color="primary">*/}
			{/*            View profile*/}
			{/*        </Button>*/}
			{/*    </Box>*/}
			{/*</div>*/}

			<div className={classes.proButtonsMobile}>
				{!leadKey ? (
					<React.Fragment>
						<div className={classes.flexBasis}>
							<Button
								onClick={contactOnClickHandler}
								fullWidth
								disableElevation
								variant="contained"
								color="primary"
								size="small"
							>
								{clientCallToAction.lead.charAt(0).toUpperCase() +
									clientCallToAction.lead.slice(1)}
							</Button>
						</div>
						<div className={classes.flexBasis}>
							<Button fullWidth variant="outlined" color="primary" size="small">
								View profile
							</Button>
						</div>
					</React.Fragment>
				) : (
					<Button
						fullWidth
						onClick={sendRequestOnClickHandler}
						disableElevation
						size={"small"}
						variant="contained"
						color="primary"
						startIcon={<SendOutlinedIcon />}
					>
						Send request
					</Button>
				)}
			</div>
		</div>
	);
});

ProListItemContent.propTypes = {
	provider: PropTypes.object.isRequired,
};

export default ProListItemContent;
