import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    onlineNow: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginRight: 4,
        width: 20,
        height: 20,
    },
    onlineBox: {
        marginRight: 4,
        marginLeft: 4,
    },
    onlinePulse: {
        display: "inline-block",
        width: 10,
        height: 10,
        borderRadius: "50%",
        background: "#50c878",
        cursor: "pointer",
        boxShadow: "0 0 0 rgba(80, 200, 120, 0.4)",
        animation: "$pulse 2s infinite",
    },
    '@keyframes pulse': {
        '0%': {
            "-moz-box-shadow": "0 0 0 0 rgba(80, 200, 120, 0.4)",
            boxShadow: "0 0 0 0 rgba(80, 200, 120, 0.4)",
        },
        '70%': {
            "-moz-box-shadow": "0 0 0 10px rgba(80, 200, 120, 0)",
            boxShadow: "0 0 0 10px rgba(80, 200, 120, 0)",
        },
        '100%': {
            "-moz-box-shadow": "0 0 0 0 rgba(80, 200, 120, 0)",
            boxShadow: "0 0 0 0 rgba(80, 200, 120, 0)",
        },
    },
}));

const OnlinePulse = () => {

    const classes = useStyles();

    return (
        <div className={classes.onlineNow}>
            <div className={classes.onlineBox}>
                <span className={classes.onlinePulse}/>
            </div>
        </div>
    );
};

OnlinePulse.propTypes = {};
export default OnlinePulse;