import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { observer } from "mobx-react";

// Libs
import withStyles from "@framework/src/libs/withStyles";

// Components
import ProListItem from "./ProListItem";

// MUI
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

// Config
import { styles, stores } from "./.config";

import useStores from "@hooks/useStores";
import useError from "@hooks/useError";
import withStores from "@libs/framework/src/libs/withStores";

import SendProject from "@components/Global/Custom/SendProject";
import LazyLoadComponent from "@custom/LazyLoad";

const ProList = compose(
	withStores(stores),
	withStyles(styles),
	observer
)(
	({
		providers = [],
		variant,
		serveCity,
		seeMoreOnClickHandler,
		forceShowAllPros,
		onMouseEnter,
		onMouseLeave,
	}) => {
		const { proListStore: store } = useStores();
		const [ErrorTemplate, onError] = useError();

		useEffect(() => {
			store.init(providers, forceShowAllPros).catch(onError);
		}, [providers]);

		useEffect(() => {
			store
				.setOpenLead(store.searchPanelStore?.selectedServiceKey)
				.catch(onError);
		}, [
			store.searchPanelStore?.selectedServiceKey,
			store.searchPanelStore?.zipcode,
			store.serviceKey,
		]);

		const seeMoreOnClickHandlerProxy = () => {
			if (!seeMoreOnClickHandler) {
				store.seeMore();
				return;
			}

			seeMoreOnClickHandler.then
				? seeMoreOnClickHandler().catch(onError)
				: seeMoreOnClickHandler();
		};
		return (
			<React.Fragment>
				{store.providers.map((provider) => (
					<ProListItem
						onMouseLeave={onMouseLeave}
						onMouseEnter={onMouseEnter}
						key={provider._id}
						serveCity={serveCity}
						leadKey={store.lead?.key}
						provider={provider}
						variant={variant}
					/>
				))}
				{(store.showSeeMore || seeMoreOnClickHandler) && !forceShowAllPros && (
					<Box textAlign="center" mt={16}>
						<Button
							onClick={seeMoreOnClickHandlerProxy}
							variant="outlined"
							size="large"
							color="primary"
						>
							See more
						</Button>
					</Box>
				)}
				<SendProject leadKey={store.lead?.key} />
				<ErrorTemplate />
			</React.Fragment>
		);
	}
);

ProList.defaultProps = {
	variant: "ssr",
	allowLeadFunnel: false,
	forceShowAllPros: false,
	onMouseEnter: () => {},
	onMouseLeave: () => {},
};

ProList.propTypes = {
	providers: PropTypes.arrayOf(PropTypes.shape({})),
	variant: PropTypes.oneOf(["ssr", "spa", "window"]),
	allowLeadFunnel: PropTypes.bool,
	forceShowAllPros: PropTypes.bool,
	onMouseEnter: PropTypes.func,
	onMouseLeave: PropTypes.func,
	seeMoreRouteToSearch: PropTypes.bool,
	serveCity: PropTypes.shape({
		city: PropTypes.string,
		state: PropTypes.string,
	}),
};

export default ProList;
